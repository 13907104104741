import React, { useEffect, useState, useRef } from 'react';
import "./ComingSoon.css";
import { animate, stagger, easeIn, easeInOut, motion } from "framer-motion";
import KizuLetterKHaze from '../../Assets/KizuLetterKHaze.webp';
import KizuLetterIKai from '../../Assets/KizuLetterIKai.webp';
import KizuLetterZMiyako from '../../Assets/KizuLetterZMiyako.webp';
import KizuLetterUZero from '../../Assets/KizuLetterUZero.webp';
/* import backgroundVideo from "../../Assets/ComingSoonBackground.mov"; */
import { Tooltip } from 'react-tooltip';


const ComingSoon = () => {
    const videoRef = useRef();
    const [loading, setLoading] = useState(true);
    const [logoShown, setLogoShown] = useState(false);
    const letterMotion = { "K": 1, "I": 0, "Z": 1, "U": -1 };
    const visitedBefore = sessionStorage.getItem('visitedBefore');
    const [tooltip, setTooltip] = useState({ visible: false, text: '', x: 0, y: 0 });

    const [linksAreLive, setLinksAreLive] = useState(false);// Set to true when links are live

    /*     useEffect(() => {
            const videoElement = videoRef.current;

            const handleLoadedData = () => {
                setLoading(false);
                videoElement.style.opacity = '1';
            };

            videoElement.addEventListener('loadeddata', handleLoadedData);

            return () => {
                videoElement.removeEventListener('loadeddata', handleLoadedData);
            };
        }, []); */

    const getLetterPath = (letter) => {
        const paths = {
            K: "m144.355 117.779c-4.2 4.6-8.3 9.3-12.5 13.9-10.4 11.5-20.8 23.1-31.2 34.6-.7.8-.7 1.3-.2 2.1 11.8 21 23.5 42 35.3 63.1 3.5 6.3 7 12.5 10.5 18.8.1.2.2.5.4 1-.6 0-1 0-1.4 0-21.7 0-43.4 0-65 0-1.1 0-1.7-.4-2.1-1.4-5.8-12.4-11.6-24.8-17.4-37.2-.2-.3-.4-.6-.6-1.1-.4.4-.8.7-1.1 1-4 4.4-8.1 8.9-12 13.4-.4.5-.7 1.2-.7 1.9 0 7.3 0 14.6 0 21.9 0 1.2-.4 1.5-1.5 1.5-14.3 0-28.7 0-43 0q-1.6 0-1.6-1.7c0-43.3 0-86.7 0-130 0-.6 0-1.2-.1-1.8 15.4 0 30.9 0 46.3 0 0 .3-.1.6-.1 1 0 11.4 0 22.8 0 34.3 0 .3 0 .7.1 1.4.7-.7 1.1-1.2 1.6-1.7 9.2-11.2 18.4-22.5 27.6-33.7.3-.3.4-.8.6-1.1Z",
            I: "m173.7,117.7c0,.6-.1,1.2-.1,1.8,0,43.2,0,86.5,0,129.7q0,1.9-1.9,1.9c-5.8,0-11.6,0-17.4,0-1,0-1.6-.3-2-1.2-9.2-16.5-18.5-33-27.7-49.6-.4-.7-.6-1.5-.6-2.3,0-16.2,0-32.4,0-48.5,0-.9.3-1.6.9-2.3,8.5-9.4,17-18.8,25.5-28.3.4-.4.6-.9.9-1.4,7.5,0,14.9,0,22.4,0Z",
            Z: "m283.1,117.7c0,.6-.1,1.1-.1,1.7,0,14.4,0,28.8,0,43.1,0,1.5-.4,2.5-1.4,3.5-12.4,12.3-24.7,24.7-37,37-.4.4-.7.8-1,1.1,0,.1,0,.2.1.3h1.5c12.1,0,24.1,0,36.2,0q1.6,0,1.6,1.6c0,14.5,0,28.9,0,43.4q0,1.6-1.6,1.6c-33.8,0-67.7,0-101.5,0q-1.6,0-1.6-1.6c0-14.5,0-29.1,0-43.6,0-1,.3-1.8,1-2.5,12.4-12.5,24.8-24.9,37.2-37.4.4-.4.7-.8,1.3-1.4-.9,0-1.4-.2-1.9-.2-11.9,0-23.8,0-35.7,0q-1.8,0-1.8-1.8c0-14.5,0-28.9,0-43.4,0-.5,0-1.1-.1-1.6,35,0,70,0,104.9,0Z",
            U: "m414.7,117.7c0,.6-.1,1.2-.1,1.8,0,43.3,0,86.5,0,129.8q0,1.9-2,1.9c-40,0-80,0-119.9,0q-1.9,0-1.9-1.9c0-43.2,0-86.4,0-129.6,0-.7,0-1.3,0-2,16.6,0,33.2,0,49.8,0,0,.4-.1.8-.1,1.2,0,26.5,0,53,0,79.5,0,1.2.4,1.5,1.5,1.5,7.1,0,14.3,0,21.4,0,1.3,0,1.6-.5,1.6-1.6,0-26.9,0-53.7,0-80.6,16.6,0,33.2,0,49.8,0Z"
        };
        return paths[letter];
    };

    const getLetterVariants = (id) => ({
        normal: {
            opacity: logoShown ? 1 : 0,
            fill: `url(#pattern-${id})`, //#b788c4
            fillOpacity: 0,
            scale: 1,
            x: 0,
            cursor: "auto",
            transition: {
                duration: 0.25,
                ease: easeIn,
            }
        },
        hover: {
            opacity: 1,
            fill: `url(#pattern-${id})`,
            fillOpacity: 1,
            scale: 1.025,
            x: letterMotion[id],
            cursor: "pointer",
            transition: {
                duration: 0.25,
                ease: easeIn,
            }
        },
        click: {
            opacity: 1,
            fill: `url(#pattern-${id})`,
            fillOpacity: .5,
            scale: 0.99,
            x: -1 * letterMotion[id],
            cursor: "pointer",
            transition: {
                duration: 0.1,
                ease: easeIn,
            }
        }
    });

    const letterTooltips = {
        K: "JOIN OUR DISCORD SERVER",
        I: "FOLLOW US ON X (TWITTER)",
        Z: "FOLLOW US ON INSTAGRAM",
        U: "CHECK US OUT ON OPENSEA"
    };

    const handleMouseEnter = (letter, e) => {
        const rect = e.target.getBoundingClientRect();
        setTooltip({
            visible: true,
            text: letterTooltips[letter],
            x: rect.left + rect.width / 2, // Center it horizontally
            y: rect.bottom // Set it to the bottom of the element
        });
    };


    const handleMouseLeave = () => {
        setTooltip({ visible: false, text: '', x: 0, y: 0 });
    };

    const handleKClick = () => {
        console.log('K clicked');
        if (linksAreLive)
            window.open('https://discord.gg/ZBkFCct6', '_blank');
    };

    const handleIClick = () => {
        console.log('I clicked');
        if (linksAreLive)
            window.open('https://twitter.com/KizuNFT', '_blank');
    };

    const handleZClick = () => {
        console.log('Z clicked');
        if (linksAreLive)
            window.open('https://instagram.com/kizunft', '_blank');
    };

    const handleUClick = () => {
        console.log('U clicked');
        if (linksAreLive)
            window.open('https://opensea.io', '_blank');
    };

    let delayTime = 600;
    if (!visitedBefore)
        delayTime = 6000;

    const soonText = "SOON...".split("");

    const unveilAnimation = async () => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        await delay(delayTime);
        animate(
            ".kizu-letter",
            {
                opacity: 1,
                transition: { duration: 0.25, ease: easeIn }
            },
            { delay: stagger(0.2) },
        );
        await delay(5000);
        await animate(
            ".kizu-letter",
            {
                fillOpacity: 1,
                scale: 1.05,
                transition: { duration: (0.25), ease: easeIn }
            },
            { delay: stagger(0.15) },
        );
        await animate(
            ".kizu-letter",
            {
                fillOpacity: 0,
                scale: 1,
                transition: { duration: 0.25, ease: easeIn }
            },
            { delay: stagger(0.15) },
        );
        setLogoShown(true);
        console.log("unveilAnimation done");
    };

    useEffect(() => {
        unveilAnimation();
    }, []);

    const MobileFooter = () => {
        return (
            <div className='coming-soon-mobile-footer'>
            <h4>
                <a href="https://discord.gg/ZBkFCct6" target="_blank" rel="noreferrer">
                    DISCORD
                </a>
                <span> / </span>
                <a href="https://twitter.com/KizuNFT" target="_blank" rel="noreferrer">
                    TWITTER
                </a>
                <span> / </span>
                <a href="https://instagram.com/kizunft" target="_blank" rel="noreferrer">
                    INSTAGRAM
                </a>
                <span> / </span>
                <a href="https://opensea.io" target="_blank" rel="noreferrer">
                    OPENSEA
                </a>
            </h4>
        </div>
        )
    };


    return (
        <div className='coming-soon-page-wrapper'>
            {/*             <video
                className="coming-soon-bg-video"
                ref={videoRef}
                onCanPlay={() => videoRef.current.playbackRate = 0.5}
                autoPlay
                loop
                muted
                style={{
                    opacity: loading ? 0 : 1,
                    transition: 'opacity 2s ease-in-out',
                }}
            >
                <source src={backgroundVideo} type="video/mp4" />
            </video> */}
            {tooltip.visible && linksAreLive && (
                <motion.div
                    className='tooltip'
                    initial={{
                        opacity: 0,
                        scale: 0.5,
                        transform: 'translate(-50%, 0)',
                        transition: {
                            duration: 0.25,
                            ease: easeIn,
                        }

                    }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        transform: 'translate(-50%, 0)',
                        transition: {
                            duration: 0.25,
                            ease: easeIn,
                        }
                    }}
                    style={{
                        position: 'absolute',
                        top: tooltip.y + 10, // Slightly below the element
                        left: tooltip.x,
                        pointerEvents: 'none',
                        backgroundColor: '#9058A0',
                        color: '#FFFFFF',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        fontSize: '14px',
                        fontWeight: '500',
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        zIndex: 1000
                    }}>
                    {tooltip.text}
                </motion.div>
            )}
            <motion.div className='coming-soon-container'>
                <motion.svg viewBox="-20 -20 440 340" className='logo-svg-container'>
                    <defs>
                        <pattern id="pattern-K" patternUnits="objectBoundingBox" width="100%" height="100%">
                            <image href={KizuLetterKHaze} x="0" y="0" width="146px" height="133px" />
                        </pattern>
                        <pattern id="pattern-I" patternUnits="objectBoundingBox" width="100%" height="100%">
                            <image href={KizuLetterIKai} x="0" y="0" width="50px" height="133px" />
                        </pattern>
                        <pattern id="pattern-Z" patternUnits="objectBoundingBox" width="100%" height="100%">
                            <image href={KizuLetterZMiyako} x="0" y="0" width="105px" height="133px" />
                        </pattern>
                        <pattern id="pattern-U" patternUnits="objectBoundingBox" width="100%" height="100%">
                            <image href={KizuLetterUZero} x="0" y="0" width="124px" height="133px" />
                        </pattern>
                    </defs>
                    {['K', 'I', 'Z', 'U'].map((letter, b) => (
                        <motion.path
                            key={b}
                            className={`pattern-background-letter-${letter.toLowerCase()}, kizu-letter-background, tooltip`}
                            d={getLetterPath(letter)}
                            fill="#b788c4"
                            initial={{
                                opacity: 0,
                                scale: 0.5
                            }}
                            animate={{
                                opacity: 1,
                                scale: 1,
                            }}
                            transition={{
                                duration: 0.25,
                                ease: easeIn,
                                delay: (b / 10) + (delayTime / 1000 + 1)
                            }}
                        />
                    ))}
                    {['K', 'I', 'Z', 'U'].map((letter) => (
                        <motion.path
                            key={letter}
                            className={`coming-soon-letter-${letter.toLowerCase()}, kizu-letter`}
                            d={getLetterPath(letter)}
                            strokeWidth="2"
                            stroke="#9058a0"
                            strokeLinecap="butt"
                            variants={getLetterVariants(letter)}
                            initial="normal"
                            whileHover="hover"
                            whileTap="click"
                            onHoverStart={e => handleMouseEnter(letter, e)}
                            onHoverEnd={handleMouseLeave}
                            onTap={() => {
                                switch (letter) {
                                    case 'K': handleKClick(); break;
                                    case 'I': handleIClick(); break;
                                    case 'Z': handleZClick(); break;
                                    case 'U': handleUClick(); break;
                                    default: break;
                                }
                            }}
                        />
                    ))}
                </motion.svg>
                <motion.div className='coming-soon-text-container'>
                    {soonText.map((el, i) => (
                        <motion.h4
                            className='coming-soon-text'
                            key={i}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 0.25,
                                delay: (i / 3) + (delayTime / 1000 + 2),
                            }}
                        >
                            {el}{" "}
                        </motion.h4>
                    ))}
                </motion.div>
            </motion.div>
            {linksAreLive && MobileFooter()}
        </div>
    );
}

export default ComingSoon;