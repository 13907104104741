import React, { useEffect, useRef, useState } from "react";
import "./Exhibits.css";
import backgroundVideo from "./Assets/exhibitsBackground3.mp4";
import TransitionSwipe from "../../Components/Animations/Transition";
import { animate, motion, useIsPresent, easeInOut, AnimatePresence } from "framer-motion";
import { automatedThemeChange, FavoriteThemeTooltip } from "../../Utils/ThemeProvider";
import { useLocation } from "react-router-dom";

const Exhibits = () => {
    const location = useLocation();
    const kizu = { index: 0, name: 'kizu' };
    useEffect(() => {
        automatedThemeChange(location, kizu);
    }, []);

    const videoRef = useRef();

    const [loading, setLoading] = useState(true);
    const [clickedClose, setClickedClose] = useState(false);
    const [clickedOpen, setClickedOpen] = useState(false);

    useEffect(() => {
        const videoElement = videoRef.current;

        const handleLoadedData = () => {
            setLoading(false);
            videoElement.style.opacity = '1';
        };

        videoElement.addEventListener('loadeddata', handleLoadedData);

        return () => {
            videoElement.removeEventListener('loadeddata', handleLoadedData);
        };
    }, []);

    const cards = ['A', 'B', 'C', 'D']
    const [selectedId, setSelectedId] = useState(null);

    const openSelected = (id) => {
        if (selectedId !== cards[id]) {
            setSelectedId(cards[id]);
        }
        setClickedOpen(true);
    };

    const closeSelected = () => {
        setSelectedId(null);
        setClickedClose(true);
    };

    useEffect(() => {
        if (selectedId !== null && clickedOpen) {
            const closedCards = document.querySelectorAll('.card');
            closedCards.forEach(card => {
                animate(card, { opacity: [null, null, 0], transition: { duration: 1.5 } });
            });
            setClickedOpen(false);
        } else if (selectedId === null && clickedClose) {
            const cards = document.querySelectorAll('.card');
            cards.forEach(card => {
                animate(card, { opacity: [null, null, 1], transition: { duration: 1.5 } });
            });
            setClickedClose(false);
        }
    }, [selectedId, clickedOpen, clickedClose])


    const CloseButton = () => {
        return (
            <motion.button
                className="xButtonBackground"
                style={{ clipPath: 'circle(16px at 50% 50%)' }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                onClick={closeSelected}
            >
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path fill="transparent" strokeWidth="3" stroke="var(--icon-color)" d="M 2 2 L 22 22" />
                    <path fill="transparent" strokeWidth="3" stroke="var(--icon-color)" d="M 2 22 L 22 2" />
                </svg>
            </motion.button>
        );
    };

    const isPresent = useIsPresent();

    const gridVariants = {
        hidden: {
            opacity: 0
        },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.33,
                delayChildren: 2.67,
                duration: 2,
            }
        }
    }

    const gridOutlineVariants = {
        hidden: {
            pathLength: 0,
            strokeWidth: 4,
        },
        show: {
            pathLength: 1,
            strokeWidth: 4,
            stroke: "var(--icon-color)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transition: {
                duration: 0.5,
                ease: easeInOut,
                delay: 1,
            }
        }
    }

    const gridFillVariants = {
        hidden: {
            opacity: 0,
            strokeWidth: 0,
            scale: 0,
        },
        show: {
            opacity: 1,
            strokeWidth: 0,
            scale: 1,
            fill: "var(--icon-color-transparent)",
            transition: {
                duration: 0.5,
                ease: easeInOut,
                delay: 1.5
            }
        }
    }

    const cardDataByIndex = [
        { name: 'A', originX: 100, originY: 100 },
        { name: 'B', originX: -200, originY: 100 },
        { name: 'C', originX: 200, originY: -100 },
        { name: 'D', originX: -100, originY: -100 },
    ];

    const cardVariants = (id) => {
        let cardX = cardDataByIndex[id].originX
        let cardY = cardDataByIndex[id].originY
        return {
            hidden: {
                opacity: 0,
                x: cardX,
                y: cardY,
            },
            show: {
                opacity: 1,
                x: 0,
                y: 0,
                transition: { duration: .67, ease: "backOut" },
            }
        }
    }

    // Favorite theme loaded tooltip
    const [showThemeTooltip, setShowThemeTooltip] = useState(false);
    const [favoriteThemeExists, setFavoriteThemeExists] = useState(false);

    useEffect(() => {
        const favoriteThemeLoaded = sessionStorage.getItem('favoriteThemeLoaded');
        const favoriteTheme = JSON.parse(localStorage.getItem("favorite-theme"));

        if (favoriteTheme) {
            setFavoriteThemeExists(true);
            console.log("set favorite theme exists to true")
        }
        else {
            setFavoriteThemeExists(false);
            console.log("set favorite theme exists to false")
        }

        if (!favoriteThemeLoaded) {
            setShowThemeTooltip(true);
            const timer = setTimeout(() => {
                setShowThemeTooltip(false);
            }, 2500);
            return () => {
                clearTimeout(timer);
            }
        }
    }, []);

    const FavoriteThemeTooltipContainer = (show) => {
        return (
            <AnimatePresence>
                {show && FavoriteThemeTooltip(favoriteThemeExists)}
            </AnimatePresence>
        )
    };

    return (
        <div className="exhibits-page-desktop-wrapper">
            <video
                className="exhibits-bg-video"
                ref={videoRef}
                onCanPlay={() => videoRef.current.playbackRate = 0.75}
                autoPlay
                loop
                muted
                style={{
                    opacity: loading ? 0 : 1,
                    transition: 'opacity 2s ease-in-out',
                }}
                onClick={selectedId !== null ? closeSelected : null}
            >
                <source src={backgroundVideo} type="video/mp4" />
            </video>
            <div className="exhibits-page-desktop-group">
                <div className="exhibits-header-group">
                    <h1 className="exhibits-header">Exhibits</h1>
                    <h4 className="exhibits-subheader">Delve into the world of Kizu.</h4>
                    <p className="exhibits-body-text">
                        Kizu is a brand dedicated to curating a community of like-minded creatives, hustlers, and motivated
                        individuals bent on dominating life. Lorem ipsum dolor et amet your profile picture determines your
                        hierarchy within our secret society. This is just example filler text meant to showcase the font’s
                        aesthetic alongside a minimalist background.<br /><br />
                        KIZU [kì-zú] - noun. Def - scar, wound
                    </p>
                </div>

            </div>
            <AnimatePresence>
                <motion.div
                    variants={gridVariants}
                    initial="hidden"
                    animate="show"

                >
                    <motion.svg
                        className={"exhibits-grid-group-container"}
                        viewBox="0 0 256 200"
                        fill="none"
                    >
                        <motion.polygon
                            variants={gridOutlineVariants}
                            points="0 20, 20 0, 256 0, 256 180, 236 200, 0 200, 0 20"
                        />
                        <motion.polygon
                            variants={gridFillVariants}
                            points="2 18, 18 2, 254 2, 254 182, 238 198, 2 198, 2 22"
                        />
                    </motion.svg>
                    <motion.div className="exhibits-grid-group">
                        <motion.div
                            layout
                            className={`exhibits-card-A ${selectedId === 'A' ? 'opened-card' : 'card'}`}
                            variants={cardVariants(0)}
                            whileHover={selectedId !== 'A' ? { scale: 1.02 } : {}}
                            whileTap={selectedId !== 'A' ? { scale: 0.98 } : {}}
                            onClick={() => openSelected(0)}
                        >
                            {selectedId === 'A' && <CloseButton />}
                            <div className="exhibits-text-wrapper">
                                {selectedId !== 'A' && [<h3 className="exhibits-text-header closed-header" key={0} >Phase 1</h3>]}
                                {selectedId === 'A' && (
                                    <>
                                        <div className="exhibits-open-card-text">
                                            <h3 className="exhibits-open-card-text-header" key={1} >
                                                Phase 1
                                            </h3>
                                            <h5 className="exhibits-open-card-text-body">
                                                <br /><b>The journey begins.</b><br />
                                                <br />
                                                Inspired by Japanese culture, a diverse collection of 5,000 avatars are unlocked. <br />
                                                <br />
                                                Hand drawn by the magnificent <a href="https://x.com/mulan_art">Mulan</a>, each Kizu enters Web3.
                                            </h5>
                                        </div>
                                    </>
                                )}
                            </div>
                        </motion.div>
                        <motion.div
                            layout
                            className={`exhibits-card-B ${selectedId === 'B' ? 'opened-card' : 'card'}`}
                            variants={cardVariants(1)}
                            whileHover={selectedId !== 'B' ? { scale: 1.02 } : {}}
                            whileTap={selectedId !== 'B' ? { scale: 0.98 } : {}}
                            onClick={() => openSelected(1)}
                        >
                            {selectedId === 'B' && <CloseButton />}
                            <div className="exhibits-text-wrapper">
                                {selectedId !== 'B' && <h3 className="exhibits-text-header closed-header" key={0} >Phase 2</h3>}
                                {selectedId === 'B' && (
                                    <>
                                        <div className="exhibits-open-card-text">
                                            <h3 className="exhibits-open-card-text-header" key={1} >
                                                Phase 2
                                            </h3>
                                            <h5 className="exhibits-open-card-text-body">
                                                <br /><b>Operation Java</b><br />
                                                <br />
                                                Kizu's first physical collaboration. <br />
                                                <br />
                                                Who is Java? What is Java? This is no ordinary NFT collaboration consisting of cheap plastic toys and collectibles.
                                            </h5>
                                        </div>
                                    </>
                                )}
                            </div>
                        </motion.div>
                        <motion.div
                            layout
                            className={`exhibits-card-C ${selectedId === 'C' ? 'opened-card' : 'card'}`}
                            variants={cardVariants(2)}
                            whileHover={selectedId !== 'C' ? { scale: 1.02 } : {}}
                            whileTap={selectedId !== 'C' ? { scale: 0.98 } : {}}
                            onClick={() => openSelected(2)}
                        >
                            {selectedId === 'C' && <CloseButton />}
                            <div className="exhibits-text-wrapper">
                                {selectedId !== 'C' && <h3 className="exhibits-text-header closed-header" key={0} >Phase 3</h3>}
                                {selectedId === 'C' && (
                                    <>
                                        <div className="exhibits-open-card-text">
                                            <h3 className="exhibits-open-card-text-header" key={1} >
                                                Phase 3
                                            </h3>
                                            <h5 className="exhibits-open-card-text-body">
                                                <br /><b>Operation Ghoul. </b><br />
                                                <br />
                                                It’s no secret the Kizu ecosystem will expand beyond the initial 5,000. The way we create this expansion is the fun part. This is no ordinary airdrop or secondary collection. The Ghouls are coming.
                                            </h5>
                                        </div>
                                    </>
                                )}
                            </div>
                        </motion.div>
                        <motion.div
                            layout
                            className={`exhibits-card-D ${selectedId === 'D' ? 'opened-card' : 'card'}`}
                            variants={cardVariants(3)}
                            whileHover={selectedId !== 'D' ? { scale: 1.02 } : {}}
                            whileTap={selectedId !== 'D' ? { scale: 0.98 } : {}}
                            onClick={() => openSelected(3)}
                        >
                            {selectedId === 'D' && <CloseButton />}
                            <div className="exhibits-text-wrapper">
                                {selectedId !== 'D' && <h3 className="exhibits-text-header closed-header" key={0} >Phase 4</h3>}
                                {selectedId === 'D' && (
                                    <>
                                        <div className="exhibits-open-card-text">
                                            <h3 className="exhibits-open-card-text-header" key={1} >
                                                Phase 4
                                            </h3>
                                            <h5 className="exhibits-open-card-text-body">
                                                <br /><b>Operation Ironside. </b><br />
                                                <br />
                                                Introducing our second physical collaboration. Ironside will be our first merch/collectible release. Hand-stitched custom basketball jerseys are coming your way. These jerseys will be premium, high-end official jerseys. Be on the look out. #Daijobu
                                            </h5>
                                        </div>
                                    </>
                                )}
                            </div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </AnimatePresence>
            {FavoriteThemeTooltipContainer(showThemeTooltip)}
            {TransitionSwipe(isPresent)}
        </div >
    );
};
export default Exhibits;