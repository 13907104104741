import React from "react";
import "./HomeZero.css";
import { motion } from "framer-motion"
import ZeroCharacter from "./img/ZeroCharacter.webp"
import { useSwiper } from 'swiper/react';
import { Textfit } from 'react-textfit';
import kizuLogo from '../../../Assets/KizuLogoWHiragana.svg';

const HomeZero = () => {

  const swiper = useSwiper();

  function handlePrev() {
    console.log("handlePrev");
    swiper.slidePrev();
  };

  function handleNext() {
    console.log("handleNext");
    swiper.slideNext();
  };

  return (
    <div className="home-zero-wrapper">
      <div className="home-zero-group">
      <svg className="home-zero-kizuLogoBackground" src={kizuLogo} />
        <div className='home-zero-index-wrapper'>
          <div className={"home-zero-BioBoxGroup"}>
            <div className={"home-zero-BioBoxWithNavButtons"}>
              <Textfit mode="multi" max={38} className={"home-zero-Attributes"}>
                Height: 200 cm{"\n"}Favorite food: Pizza
              </Textfit>
              <Textfit mode="multi" max={36} className={"home-zero-BioText"}>
                Zero is not just a baller on the court -- his athleticism and agility make him a formidable opponent in any fight. He’s also a bit of a goofball, but he’s always there for his friends.
              </Textfit>
              <div className={"home-zero-PreviousButtonWrapper"}>
                <motion.div
                  onClick={(event) => handlePrev(event)}
                  className={"home-zero-ButtonWrapper"}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "tween", duration: 0.05 }}
                >
                  <button className={"home-zero-PrevNextButton"}>
                    <Textfit mode="single" forceSingleModeWidth={true} max={48} className={"home-zero-PrevNextButtonText"}>
                      PREV
                    </Textfit>
                  </button>
                </motion.div>
              </div>
              <div className={"home-zero-NextButtonWrapper"}>
                <motion.div
                  onClick={(event) => handleNext(event)}
                  className={"home-zero-ButtonWrapper"}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "tween", duration: 0.05 }}
                >
                  <button className={"home-zero-PrevNextButton"}>
                    <Textfit mode="single" forceSingleModeWidth={true} max={48} className={"home-zero-PrevNextButtonText"}>
                      NEXT
                    </Textfit>
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <Textfit mode="single" max={96} className="home-zero-name" style={{ fontWeight: '900', color: 'white', textShadow: '-2px 2px 2px #363636bf, 2px 2px 2px #363636bf, 2px -2px 2px #363636bf, -2px -2px 2px #363636bf' }}>
          ZERO
        </Textfit>
        <div className="home-zero-placard-group">
          <img src={ZeroCharacter} className="home-zero-character" alt="Zero Character" />
        </div>
      </div>
    </div>
  );
};

export default HomeZero;
