import { useState, useEffect } from 'react';
import axios from 'axios';
import eth from '../../Assets/eth.png'
import './CollectionCard.css'

const CollectionCard = ({ id, name, image }) => {
    return (
        <div>
            <img src={image} alt="" />
            <div className="details">
                <div className="name">
                    {name} <div className="id"> #{id}</div>
                </div>

                <div className="priceContainer">
                    <img src={"https://raw.seadn.io/files/ETH.svg"} className="ethImage" alt="" />
                    <div className="price">{name[0]?.value}</div>
                </div>
            </div>
        </div>
    )
}

export default CollectionCard;
