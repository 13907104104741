import React, { useEffect, useState } from "react";
import "./Gallery.css";
import { AnimatePresence, useIsPresent } from "framer-motion";
import TransitionSwipe from "../../Components/Animations/Transition";
import axios from 'axios';
import NFTList from "../../Components/OpenSea/NFTList";
import { automatedThemeChange, FavoriteThemeTooltip } from "../../Utils/ThemeProvider";
import { useLocation } from "react-router-dom";

const Gallery = () => {
    const location = useLocation();
    const kizu = { index: 0, name: 'kizu' };

    // State to handle whether the gallery is coming soon or available
    const [galleryComingSoon, setGalleryComingSoon] = useState(true); // Set to true if gallery is coming soon
    const [nftListData, setNftListData] = useState([]);

    useEffect(() => {
        automatedThemeChange(location, kizu);
    }, [location]);

    useEffect(() => {
        if (!galleryComingSoon) {
            const getNfts = async () => {
                const openseaData = await axios.get(
                    'https://api.opensea.io/api/v2/collection/azuki/nfts', {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': '1b471b1a1e6d4f70a2c5b10fe1cdb3b2'
                    }
                });

                console.log(openseaData.data.nfts);
                setNftListData(openseaData.data.nfts);
            };
            getNfts();
        }
    }, [galleryComingSoon]);

    const isPresent = useIsPresent();
    const [showThemeTooltip, setShowThemeTooltip] = useState(false);
    const [favoriteThemeExists, setFavoriteThemeExists] = useState(false);

    useEffect(() => {
        const favoriteThemeLoaded = sessionStorage.getItem('favoriteThemeLoaded');
        const favoriteTheme = JSON.parse(localStorage.getItem("favorite-theme"));

        if (favoriteTheme) {
            setFavoriteThemeExists(true);
        } else {
            setFavoriteThemeExists(false);
        }

        if (!favoriteThemeLoaded) {
            setShowThemeTooltip(true);
            const timer = setTimeout(() => {
                setShowThemeTooltip(false);
            }, 2500);
            return () => {
                clearTimeout(timer);
            };
        }
    }, []);

    const FavoriteThemeTooltipContainer = (show) => (
        <AnimatePresence>
            {show && FavoriteThemeTooltip(favoriteThemeExists)}
        </AnimatePresence>
    );

    const renderGalleryContent = () => (
        <div>
            <h1 className="gallery-header">NFT Gallery</h1>
            <div className="gallery-text-group">
                <NFTList nftListData={nftListData} />
            </div>
            {FavoriteThemeTooltipContainer(showThemeTooltip)}
            {TransitionSwipe(isPresent)}
        </div>
    );

    const renderComingSoon = () => (
        <div>
            <h1 className="gallery-header">NFT Gallery</h1>
            <div className="gallery-text-group">
                <h2>Coming Soon!</h2>
                <div/>
                <h3>No Kizu NFTs have been minted yet!</h3>
                <div/>
                <div/>
                <div/>
            </div>
            {TransitionSwipe(isPresent)}
        </div>
    );

    return (
        <div className="gallery-page-wrapper">
            {galleryComingSoon ? renderComingSoon() : renderGalleryContent()}
        </div>
    );
};

export default Gallery;
