import React, { useEffect, useRef, useState } from "react";
import "./Team.css";
import TeamPicSmall from "./Assets/Team_small.webp";
import TeamPicMedium from "./Assets/Team_medium.webp";
import TeamPicLarge from "./Assets/Team_large.webp";
import TeamPicXLarge from "./Assets/Team_xlarge.webp";
import backgroundVideo from "./Assets/teamBackground2.mp4";
import TransitionSwipe from "../../Components/Animations/Transition";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import { automatedThemeChange, FavoriteThemeTooltip } from "../../Utils/ThemeProvider";
import { useLocation } from "react-router-dom";
import TeamMembersList from "../../Components/Team/TeamMembersList";
import { Tooltip } from 'react-tooltip';

const Team = () => {
    const location = useLocation();
    const kizu = { index: 0, name: 'kizu' };
    useEffect(() => {
        automatedThemeChange(location, kizu);
    }, []);

    const videoRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const videoElement = videoRef.current;

        const handleLoadedData = () => {
            setLoading(false);
            videoElement.style.opacity = '1';
        };

        videoElement.addEventListener('loadeddata', handleLoadedData);

        return () => {
            videoElement.removeEventListener('loadeddata', handleLoadedData);
        };
    }, []);

    const isPresent = useIsPresent();

    // Favorite theme loaded tooltip
    const [showThemeTooltip, setShowThemeTooltip] = useState(false);
    const [favoriteThemeExists, setFavoriteThemeExists] = useState(false);

    useEffect(() => {
        const favoriteThemeLoaded = sessionStorage.getItem('favoriteThemeLoaded');
        const favoriteTheme = JSON.parse(localStorage.getItem("favorite-theme"));

        if (favoriteTheme) {
            setFavoriteThemeExists(true);
            console.log("set favorite theme exists to true")
        }
        else {
            setFavoriteThemeExists(false);
            console.log("set favorite theme exists to false")
        }

        if (!favoriteThemeLoaded) {
            setShowThemeTooltip(true);
            const timer = setTimeout(() => {
                setShowThemeTooltip(false);
            }, 2500);
            return () => {
                clearTimeout(timer);
            }
        }
    }, []);

    const FavoriteThemeTooltipContainer = (show) => {
        return (
            <AnimatePresence>
                {show && FavoriteThemeTooltip(favoriteThemeExists)}
            </AnimatePresence>
        )
    };

    return (
        <div className="team-page-desktop-wrapper">
            <video
                className="team-bg-video"
                ref={videoRef}
                onCanPlay={() => videoRef.current.playbackRate = 0.75}
                autoPlay
                loop
                muted
                style={{
                    opacity: loading ? 0 : 1,
                    transition: 'opacity 2s ease-in-out',
                }}
            >
                <source src={backgroundVideo} type="video/mp4" />
            </video>
            <motion.img
                srcSet={`${TeamPicSmall} 1280w, ${TeamPicMedium} 1920w, ${TeamPicLarge} 2560w, ${TeamPicXLarge} 3840w`}
                src={TeamPicXLarge}
                className="team-bg-image"
                alt=""
                style={{
                    opacity: loading ? 0 : 1,
                    transition: 'opacity 1s ease-in',
                }}
                fluid
            />
            <div className="team-page-desktop-group">
                <h1 className="team-header">team</h1>
                <div className="team-text-group">
                    <AnimatePresence>
                        <TeamMembersList />
                    </AnimatePresence>
                </div>
            </div>
            {FavoriteThemeTooltipContainer(showThemeTooltip)}
            {TransitionSwipe(isPresent)}
        </div>
    );
};
export default Team;
