import React, { useEffect, useState, useRef } from 'react';
import './PreLoader.css';
import { motion, useAnimation } from 'framer-motion';
import KizuPreloader from './Media/KizuPreloader.mp4';

const Preloader = () => {
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const videoRef = useRef(null);
  const containerControls = useAnimation();
  

  const unveilAnimation = async () => {
    await containerControls.start({
      opacity: 0,
      transition: { duration: 1.25, ease: "anticipate" },
    });
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      let unveilTimeout = null;

      const onTimeUpdate = () => {
        if (videoElement.currentTime >= videoElement.duration - 1.25) {
          unveilAnimation();

          videoElement.removeEventListener('timeupdate', onTimeUpdate);
        }
      };

      const onVideoEnd = () => {
        setIsPreloaderVisible(false);
        sessionStorage.setItem('visitedBefore', 'true');
        console.log("preloader done");
      };
      /*       videoElement.load(); */
      videoElement.addEventListener('timeupdate', onTimeUpdate);
      videoElement.onended = onVideoEnd;
      videoElement.play();
    }
  }, []);

  return (
    <div>
      {isPreloaderVisible &&
        <motion.div className="preloaderContainer" animate={containerControls}>
          <div className="preloader">
            <div className='preloaderWrapper'>
              <div className="videoContainer">
                <video ref={videoRef} autoPlay muted playsInline>
                  <source src={KizuPreloader} type="video/mp4" />
                  Loading...
                </video>
              </div>
            </div>
          </div>
        </motion.div>
      }
    </div>
  );
};

export default Preloader;
