import React from "react";
import "./HomeHaze.css";
import { motion } from "framer-motion"
import HazeCharacter from "./img/HazeCharacter.webp"
import { useSwiper } from 'swiper/react';
import { Textfit } from 'react-textfit';
import kizuLogo from '../../../Assets/KizuLogoWHiragana.svg';

const HomeHaze = () => {

  const swiper = useSwiper();

  function handlePrev() {
    console.log("handlePrev");
    swiper.slidePrev();
  };

  function handleNext() {
    console.log("handleNext");
    swiper.slideNext();
  };

  return (
    <div className="home-haze-wrapper">
      <div className="home-haze-group">
        <svg className="home-haze-kizuLogoBackground" src={kizuLogo} />
        <div className='home-haze-index-wrapper'>
          <div className={"home-haze-BioBoxGroup"}>
            <div className={"home-haze-BioBoxWithNavButtons"}>
              <Textfit mode="multi" max={38} className={"home-haze-Attributes"}>
                Height: 190 cm{"\n"}Favorite food: Ramen
              </Textfit>
              <Textfit mode="multi" max={36} className={"home-haze-BioText"}>
                Haze is a cool and collected fighter who’s always ready to take on any challenge. Best not to test his temper, though, as brute force is often his solution.
              </Textfit>
              <div className={"home-haze-PreviousButtonWrapper"}>
                <motion.div
                  onClick={(event) => handlePrev(event)}
                  className={"home-haze-ButtonWrapper"}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "tween", duration: 0.05 }}
                >
                  <button className={"home-haze-PrevNextButton"}>
                    <Textfit mode="single" forceSingleModeWidth={true} max={48} className={"home-haze-PrevNextButtonText"}>
                      PREV
                    </Textfit>
                  </button>
                </motion.div>
              </div>
              <div className={"home-haze-NextButtonWrapper"}>
                <motion.div
                  onClick={(event) => handleNext(event)}
                  className={"home-haze-ButtonWrapper"}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "tween", duration: 0.05 }}
                >
                  <button className={"home-haze-PrevNextButton"}>
                    <Textfit mode="single" forceSingleModeWidth={true} max={48} className={"home-haze-PrevNextButtonText"}>
                      NEXT
                    </Textfit>
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <Textfit mode="single" max={96} className="home-haze-name" style={{ fontWeight: '900',color: 'white', textShadow: '-2px 2px 2px #363636bf, 2px 2px 2px #363636bf, 2px -2px 2px #363636bf, -2px -2px 2px #363636bf' }}>
          HAZE
        </Textfit>
        <div className="home-haze-placard-group">
          <img src={HazeCharacter} className="home-haze-character" alt="Haze Character" />
        </div>
      </div>
    </div>
  );
};

export default HomeHaze;
