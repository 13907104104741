import React, { useEffect, useRef, useState } from "react";
import "./Vision.css";
import backgroundVideo from "./Assets/visionBackground.mp4";
import TransitionSwipe from "../../Components/Animations/Transition"
import { useIsPresent, AnimatePresence } from "framer-motion";
import { automatedThemeChange, FavoriteThemeTooltip } from "../../Utils/ThemeProvider";
import { useLocation } from "react-router-dom";

const Vision = () => {
    const location = useLocation();
    const kizu = { index: 0, name: 'kizu' };
    useEffect(() => {
        automatedThemeChange(location, kizu);
    }, []);

    const videoRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const videoElement = videoRef.current;

        const handleLoadedData = () => {
            setLoading(false);
            videoElement.style.opacity = '1';
        };

        videoElement.addEventListener('loadeddata', handleLoadedData);

        return () => {
            videoElement.removeEventListener('loadeddata', handleLoadedData);
        };
    }, []);

    const isPresent = useIsPresent();

    // Favorite theme loaded tooltip
    const [showThemeTooltip, setShowThemeTooltip] = useState(false);
    const [favoriteThemeExists, setFavoriteThemeExists] = useState(false);

    useEffect(() => {
        const favoriteThemeLoaded = sessionStorage.getItem('favoriteThemeLoaded');
        const favoriteTheme = JSON.parse(localStorage.getItem("favorite-theme"));

        if (favoriteTheme) {
            setFavoriteThemeExists(true);
            console.log("set favorite theme exists to true")
        }
        else {
            setFavoriteThemeExists(false);
            console.log("set favorite theme exists to false")
        }

        if (!favoriteThemeLoaded) {
            setShowThemeTooltip(true);
            const timer = setTimeout(() => {
                setShowThemeTooltip(false);
            }, 2500);
            return () => {
                clearTimeout(timer);
            }
        }
    }, []);

    const FavoriteThemeTooltipContainer = (show) => {
        return (
            <AnimatePresence>
                {show && FavoriteThemeTooltip(favoriteThemeExists)}
            </AnimatePresence>
        )
    };

    return (
        <div className="vision-page-desktop-wrapper">
            <video
                className="vision-bg-video"
                ref={videoRef}
                onCanPlay={() => videoRef.current.playbackRate = 0.75}
                autoPlay
                loop
                muted
                style={{
                    opacity: loading ? 0 : 1,
                    transition: 'opacity 2s ease-in-out',
                }}
            >
                <source src={backgroundVideo} type="video/mp4" />
            </video>
            <div className="vision-page-desktop-group">
                <h1 className="vision-header">
                    Vision
                </h1>
                <div className="vision-text-group">
                    <div className="vision-text-grid-item">
                        <div className="vision-text-header">
                            Origins
                        </div>
                        <div className="vision-text-body">
                            This sample text is solely here for demonstration purposes and must be filled with corresponding text. Sample text is useful for giving the viewer a better visual understanding of what the finished product will (or should) look like. Lorem ipsum dolor et amet.
                        </div>
                    </div>
                    <div className="vision-text-grid-item">
                        <div className="vision-text-header">
                            Purpose
                        </div>
                        <div className="vision-text-body">
                            This sample text is solely here for demonstration purposes and must be filled with corresponding text. Sample text is useful for giving the viewer a better visual understanding of what the finished product will (or should) look like. Lorem ipsum dolor et amet.
                        </div>
                    </div>
                    <div className="vision-text-grid-item">
                        <div className="vision-text-header">
                            Journey
                        </div>
                        <div className="vision-text-body">
                            This sample text is solely here for demonstration purposes and must be filled with corresponding text. Sample text is useful for giving the viewer a better visual understanding of what the finished product will (or should) look like. Lorem ipsum dolor et amet.
                        </div>
                    </div>
                    <div className="vision-text-grid-item">
                        <div className="vision-text-header">
                            Destination
                        </div>
                        <div className="vision-text-body">
                            This sample text is solely here for demonstration purposes and must be filled with corresponding text. Sample text is useful for giving the viewer a better visual understanding of what the finished product will (or should) look like. Lorem ipsum dolor et amet.
                        </div>
                    </div>
                </div>
            </div>
            {FavoriteThemeTooltipContainer(showThemeTooltip)}
            {TransitionSwipe(isPresent)}
        </div>
    );
};
export default Vision;