import { useState, useEffect } from 'react';
import './TeamMemberCard.css'
import { motion } from 'framer-motion';


const TeamMemberCard = ({ image, name, description }) => {
    
    return (
            <div className='team-member-card'>
                <img className="team-member-image" src={image} alt="" />
                <div className="details">
                    <h5 className="name">
                        {name}
                    </h5>
                    <div className="description-container">
                        <p className="description">
                            {description}
                        </p>
                    </div>
                </div>
            </div>
    )
}

export default TeamMemberCard;
