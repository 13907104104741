import React, { useRef, useEffect, useState } from 'react';
import './MusicPlayer.css';
import PromiseMP3 from './Music/Sappheiros - Promise.mp3';
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { IconContext } from "react-icons";

const MusicPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [time, setTime] = useState({ min: "", sec: "" });
    const [currTime, setCurrTime] = useState({ min: "", sec: "" });
    const [seconds, setSeconds] = useState(0);
    const [duration, setDuration] = useState(0);


    const audioRef = useRef(new Audio(PromiseMP3));
    const audioCtxRef = useRef(null);

    useEffect(() => {
        const { current: audio } = audioRef;

        const handleLoadedMetadata = () => {
            const sec = audio.duration;
            const min = Math.floor(sec / 60);
            const secRemain = Math.floor(sec % 60);
            setTime({ min, sec: secRemain });
            setDuration(audio.duration);
        };

        audio.addEventListener('loadedmetadata', handleLoadedMetadata);

        return () => {
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            if (audioRef.current) {
                setSeconds(audioRef.current.currentTime);
                const min = Math.floor(audioRef.current.currentTime / 60);
                const sec = Math.floor(audioRef.current.currentTime % 60);
                setCurrTime({ min, sec });
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const playingButton = () => {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            audioRef.current.play();
            setIsPlaying(true);
            if (!audioCtxRef.current) {
                showVisualizer();
            } else {
                audioCtxRef.current.resume();
            }
        }
    };

    function showVisualizer() {
        const audio = audioRef.current;
        const visualizer = document.querySelector('.visualizer');
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        const ctx = new window.AudioContext();
        audioCtxRef.current = ctx;
        const analyser = ctx.createAnalyser();
        const source = ctx.createMediaElementSource(audio);
        source.connect(analyser);
        source.connect(ctx.destination);
        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;

        let dataArray = new Uint8Array(bufferLength);
        let elements = [];
        for (let i = 0; i < bufferLength; i++) {
            const element = document.createElement('span');
            element.classList.add('element');
            elements.push(element);
            visualizer.appendChild(element);
        }

        const clamp = (num, min, max) => {
            if (num >= max) return max;
            if (num <= min) return min;
            return num;
        }

        const update = () => {
            requestAnimationFrame(update);
            analyser.getByteFrequencyData(dataArray);
            for (let i = 0; i < bufferLength; i++) {
                let item = dataArray[i];
                item = item > 25 ? item * .75 : item * 1.25;
                elements[i].style.transform = `rotateZ(${i * (360 / bufferLength)}deg) translate(-50%, ${clamp(item, 30, 180)}px)`;
            }
        };
        update();
    }

    return (
        <div className="musicPlayer">
            <div className="box">
                <audio ref={audioRef} src={PromiseMP3} preload="auto" />
                <div className="component">
                    <div>
                        <p className="title">Promise</p>
                        <p className="subTitle">Sappheiros</p>
                    </div>
                    <div>
                        <div className="time">
                            <p>
                                {currTime.min}:{currTime.sec.toString().padStart(2, '0')}
                            </p>
                            <p>
                                {time.min}:{time.sec.toString().padStart(2, '0')}
                            </p>
                        </div>
                        <input
                            type="range"
                            min="0"
                            max={duration}
                            value={seconds}
                            className="timeline"
                            onChange={(e) => {
                                audioRef.current.currentTime = e.target.value;
                                setSeconds(e.target.value);
                            }}
                        />
                    </div>
                    <div>
                        <button className="playButton">
                            <IconContext.Provider value={{ size: "3em", color: "var(--icon-color-secondary)" }}>
                                <BiSkipPrevious />
                            </IconContext.Provider>
                        </button>
                        {!isPlaying ? (
                            <button className="playButton" onClick={playingButton}>
                                <IconContext.Provider value={{ size: "3em", color: "var(--icon-color-secondary)" }}>
                                    <AiFillPlayCircle />
                                </IconContext.Provider>
                            </button>
                        ) : (
                            <button className="playButton" onClick={playingButton}>
                                <IconContext.Provider value={{ size: "3em", color: "var(--icon-color-secondary)" }}>
                                    <AiFillPauseCircle />
                                </IconContext.Provider>
                            </button>
                        )}
                        <button className="playButton">
                            <IconContext.Provider value={{ size: "3em", color: "var(--icon-color-secondary)" }}>
                                <BiSkipNext />
                            </IconContext.Provider>
                        </button>
                    </div>
                </div>
                <div className="visualizer" />
            </div>
        </div>
    );
};

/* 
Sappheiros & Almaa - Dreams
Sappheiros - Passion Day 7 Remix
Sappheiros - Universe
Sappheiros - Promise
Sappheiros - Spirit
Sappheiros - Miracle
Electus - Temple of Light (Sappheiros Remix)
Sappheiros - Falling (ft. eSoreni)
Sappheiros - Dawn
Sappheiros - A Tale of Unexplored Worlds
 
 */

// TODO: Add a visualizer for the music and add animation to the music player 

// TODO: ADD A VOLUME SLIDER

export default MusicPlayer;
