import React, { useEffect, useState } from "react";
import HomeHaze from '../HomeHaze/HomeHaze';
import HomeKai from '../HomeKai/HomeKai';
import HomeMiyako from '../HomeMiyako/HomeMiyako';
import HomeZero from '../HomeZero/HomeZero';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard } from "swiper";
import "./HomeIndex.css";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { automatedThemeChange, themes } from '../../../Utils/ThemeProvider';
import "../../../Styles/global.css"
import { setSwiperIndex, getSwiperIndex } from "../../../Utils/GlobalFunctions";
import TransitionSwipe from "../../Animations/Transition";
import { motion, useIsPresent } from "framer-motion";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

const HomeIndex = () => {
    const location = useLocation();
    const swiperRef = useRef(null);
    const isPresent = useIsPresent();//this is for the page transition

    const homePageSelector = (index) => {
        switch (index) {
            case 0:
                return <HomeHaze />;
            case 1:
                return <HomeKai />;
            case 2:
                return <HomeMiyako />;
            case 3:
                return <HomeZero />;
            default:
                return <HomeHaze />;
        }
    }

    const handleSlideChange = (swiperCore) => {
        const { realIndex } = swiperCore;
        console.log ("realIndex: ", realIndex);
        homePageSelector(realIndex || 0);
        setSwiperIndex(realIndex);
        automatedThemeChange(location, themes[(realIndex + 1)]) // Update the value of realIndex in the swiperIndex module
    };

    const getStartingSlide = () => {
        const favoriteTheme = JSON.parse(localStorage.getItem("favorite-theme"));
        if (favoriteTheme) {
            let startingSlide = favoriteTheme.index - 1;
            console.log("startingSlide: ", startingSlide);
            return startingSlide;
        }
        else {
            return 0;
        }

    }

    return (
        <>
            <Swiper
                ref={swiperRef}
                onInit={handleSlideChange}
                onSlideChange={handleSlideChange}
                onSwiper={(swiper) => setSwiperIndex(swiper)}
                loop={true}
                speed={750}
                navigation={true}
                pagination={true}
                keyboard={true}
                modules={[Keyboard, Pagination, Navigation]}
                className="homeSwiper"
                initialSlide={getStartingSlide()} //we need to change this once we've laid out the logic for the themes
            >
                <SwiperSlide>
                    <HomeHaze />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeKai />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeMiyako />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeZero />
                </SwiperSlide>
            </Swiper>
            {TransitionSwipe(isPresent)}
        </>
    );
};

export default HomeIndex;