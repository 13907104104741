import React from 'react';
import TeamMemberCard from './TeamMemberCard';
import './TeamMemberCard.css';
import BullbossIMG from './Assets/bullboss.webp';
import PlaceholderIMG1 from './Assets/placeholder1.webp';
import PlaceholderIMG2 from './Assets/placeholder2.webp';
import PlaceholderIMG3 from './Assets/placeholder3.webp';
import { motion } from 'framer-motion';


const TeamMembersList = () => {

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.25,
                delayChildren: 1.5,
                duration: 1,
                ease: "easeOut",
                delay: 1
            }
        },
    };

    const listItem = {
        hidden: { x: 500, y: 1500, scale: .1 },
        show: {
            x: 0,
            y: 0,
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 25
            }
        }
    };

    return (
        <motion.ul variants={container} initial="hidden" animate="show" className="team-members-list">
            <motion.li variants={listItem} className="team-member-card-wrapper">
                <TeamMemberCard
                    image={BullbossIMG}
                    name={"BullBoss"}
                    description={"BullBoss is the visionary and founder of Kizu."}
                />
            </motion.li>
            <motion.li variants={listItem} className="team-member-card-wrapper">
                <TeamMemberCard
                    image={PlaceholderIMG1}
                    name={"Person 2"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..."}
                />
            </motion.li>
            <motion.li variants={listItem} className="team-member-card-wrapper">
                <TeamMemberCard
                    image={PlaceholderIMG2}
                    name={"Person 3"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..."}
                />
            </motion.li>
            <li />
            <motion.li variants={listItem} className="team-member-card-wrapper">
                <TeamMemberCard
                    image={PlaceholderIMG3}
                    name={"Person 4"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..."}
                />
            </motion.li>
            <motion.li variants={listItem} className="team-member-card-wrapper">
                <TeamMemberCard
                    image={PlaceholderIMG3}
                    name={"Person 5"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor..."}
                />
            </motion.li>
        </motion.ul >
    );
};

export default TeamMembersList;
