import React from "react";
import "./HomeMiyako.css";
import { motion } from "framer-motion"
import MiyakoCharacter from "./img/MiyakoCharacter.webp"
import { useSwiper } from 'swiper/react';
import { Textfit } from 'react-textfit';
import kizuLogo from '../../../Assets/KizuLogoWHiragana.svg';

const HomeMiyako = () => {

  const swiper = useSwiper();

  function handlePrev() {
    console.log("handlePrev");
    swiper.slidePrev();
  };

  function handleNext() {
    console.log("handleNext");
    swiper.slideNext();
  };

  return (
    <div className="home-miyako-wrapper">
      <div className="home-miyako-group">
      <svg className="home-miyako-kizuLogoBackground" src={kizuLogo} />
        <div className='home-miyako-index-wrapper'>
          <div className={"home-miyako-BioBoxGroup"}>
            <div className={"home-miyako-BioBoxWithNavButtons"}>
              <Textfit mode="multi" max={38} className={"home-miyako-Attributes"}>
              Height: 160 cm{"\n"}Favorite food: Sushi
              </Textfit>
              <Textfit mode="multi" max={36} className={"home-miyako-BioText"}>
              Miyako isn’t just another pretty face-- she’s a lethal assassin who’s ready to fight anyone at a moment’s notice. A dangerous combination of brains, brawn, and stunning good looks!
              </Textfit>
              <div className={"home-miyako-PreviousButtonWrapper"}>
                <motion.div
                  onClick={(event) => handlePrev(event)}
                  className={"home-miyako-ButtonWrapper"}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "tween", duration: 0.05 }}
                >
                  <button className={"home-miyako-PrevNextButton"}>
                    <Textfit mode="single" forceSingleModeWidth={true} max={48} className={"home-miyako-PrevNextButtonText"}>
                      PREV
                    </Textfit>
                  </button>
                </motion.div>
              </div>
              <div className={"home-miyako-NextButtonWrapper"}>
                <motion.div
                  onClick={(event) => handleNext(event)}
                  className={"home-miyako-ButtonWrapper"}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "tween", duration: 0.05 }}
                >
                  <button className={"home-miyako-PrevNextButton"}>
                    <Textfit mode="single" forceSingleModeWidth={true} max={48} className={"home-miyako-PrevNextButtonText"}>
                      NEXT
                    </Textfit>
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <Textfit mode="single" max={96} className="home-miyako-name" style={{ fontWeight: '900', color: 'white', textShadow: '-2px 2px 2px #363636bf, 2px 2px 2px #363636bf, 2px -2px 2px #363636bf, -2px -2px 2px #363636bf' }}>
          MIYAKO
        </Textfit>
        <div className="home-miyako-placard-group">
          <img src={MiyakoCharacter} className="home-miyako-character" alt="Miyako Character" />
        </div>
      </div>
    </div>
  );
};

export default HomeMiyako;
