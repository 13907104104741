import React, { useEffect } from 'react';
import './Header.css'
import kizuLogo from '../../Assets/KizuLogoWHiragana.svg'
import { useNavigate, useMatch } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import { motion, useAnimation } from "framer-motion"
import WalletConnect from '../../Components/Web3/WalletConnect.tsx'
import '../../Styles/global.css'
import { FaExternalLinkAlt } from "react-icons/fa";

const HeaderLink = ({ to, children }) => {
    const navigate = useNavigate();
    const isActive = useMatch(to);
    const controls = useAnimation();

    const handleClick = () => {
        navigate(to);
    };

    const handleMouseEnter = () => {
        if (!isActive) {
            controls.start({ scale: 1.1 });
        }
    };

    const handleMouseLeave = () => {
        controls.start({ scale: 1 });
    };

    const handleMouseDown = () => {
        if (!isActive) {
            controls.start({ scale: 0.9 });
        }
    };

    const handleMouseUp = () => {
        if (!isActive) {
            controls.start({ scale: 1.1 });
        }
    };

    return (
        <motion.button
            style={{
                color: isActive ? 'white' : 'var(--icon-color)',
                backgroundColor: isActive ? 'var(--header-secondary-color)' : 'white'
            }}
            className={isActive ? 'active' : ''}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            animate={controls}
            initial={{ scale: 1 }}
            transition={{ type: 'tween', duration: 0.05 }}
        >
            {children}
        </motion.button>
    );
};

const handleClickMarketplace = () => {
    window.open('https://opensea.io', '_blank');
};
const handleClickTwitter = () => {
    window.open('https://twitter.com/KizuNFT', '_blank');
};
const handleClickInsta = () => {
    window.open('https://instagram.com/kizunft', '_blank');
};
const handleClickDiscord = () => {
    window.open('https://discord.gg/ZBkFCct6', '_blank');
};

const Header = (props) => {
    const headerColor = props.headerColor || 'var(--header-background-color)';

    useEffect(() => {
        const updateHeaderScale = () => {
            const viewportHeight = window.innerHeight;
            const calculatedValue = viewportHeight * 0.09259;
            document.documentElement.style.setProperty('--header-scale', calculatedValue + '%');
        };
        updateHeaderScale();
        window.addEventListener('resize', updateHeaderScale);
        return () => {
            window.removeEventListener('resize', updateHeaderScale);
        };
    }, []);


    const outlineVariants = {
        default: {
            strokeWidth: 0,
            pathLength: 0,
            stroke: "grey",
            fill: "none",
            transition: { duration: 0.5, ease: "easeOut" }
        },
        hover: {
            strokeWidth: [0.4, 1, 0.4, 1, 0.4],
            pathLength: 1,
            stroke: "white",
            fill: "none",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transition: {
                pathLength: { duration: 1, ease: "easeOut" },
                strokeWidth: { duration: 1, repeat: Infinity, repeatType: 'loop', ease: "easeOut" }
            }
        },
        tap: {
            strokeWidth: 0.4,
            pathLength: 1,
            stroke: "--header-secondary-color",
            fill: "white",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transition: { duration: 3, ease: "easeOut" }
        }
    };

    const textVariants = {
        default: {
            x: 0,
            transition: {
                duration: 1
            }
        },
        hover: {
            x: 15,
            transition: {
                duration: 0.5
            }
        }
    };

    const EthButtonAnimation = () => (
        <motion.button
            whileHover="hover"
            initial="default"
            onClick={() => WalletConnect()}
            whileTap={{ scale: 0.8 }}
            animate="default"
            className="connectButton"
            style={{
                backgroundColor: 'var(--icon-color)',
                borderColor: 'var(--icon-color-secondary)',
                color: 'white'
            }}
        >
            <motion.p variants={textVariants} className="hoverButtonText">
                CONNECT WALLET
            </motion.p>
            <motion.svg className="ethSvg" viewBox="0 0 72 24" xmlns="http://www.w3.org/2000/svg">
                <motion.path
                    className={"ethPath"}
                    variants={outlineVariants}
                    d="M11.944 17.97L4.58 13.62 11.943 24l7.37-10.38-7.372 4.35h.003zM12.056 0L4.69 12.223l7.365 4.354 7.365-4.35L12.056 0z"
                />
            </motion.svg>
        </motion.button>
    );


    return (
        <header className="desktopHeader">
            <style />
            <div className="header" style={{ backgroundColor: headerColor, color: 'var(--icon-color-secondary)', scale: 'var(--header-scale)' }}>
                <div className="logoContainer">
                    <a href="/">
                        <svg src={kizuLogo} className="kizuLogo" />
                    </a>
                </div>
                <div className="headerItems">
                    <EthButtonAnimation />
                    <HeaderLink to="/">Home</HeaderLink>
                    <HeaderLink to="/gallery">NFT Gallery</HeaderLink>
                    <HeaderLink to="/vision">Vision</HeaderLink>
                    <HeaderLink to="/exhibits">Exhibits</HeaderLink>
                    <HeaderLink to="/team">Team</HeaderLink>
                    <motion.button
                        style={{
                            color: 'var(--icon-color)',
                            backgroundColor: 'white',
                            display: 'inline-flex',
                            flexDirection: 'row'
                        }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "tween", duration: 0.05 }}
                        onClick={handleClickMarketplace}>
                        Opensea&nbsp;<FaExternalLinkAlt size={8} />
                    </motion.button>
                    <div className='socials'>
                        <motion.p className='twitterButton' whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} transition={{ type: "tween", duration: 0.05 }} onClick={handleClickTwitter}>
                            <svg className='twitterLogo' width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className='svgPath' fill="var(--header-secondary-color)" fillRule="evenodd" clipRule="evenodd" d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM11.05 5.75C11.4 5.7 11.7 5.65 12 5.5C11.8 5.85 11.5 6.15 11.15 6.35C11.25 8.7 9.55 11.25 6.5 11.25C5.6 11.25 4.75 10.95 4 10.5C4.85 10.6 5.75 10.35 6.35 9.9C5.6 9.9 5 9.4 4.8 8.75C5.05 8.8 5.3 8.75 5.55 8.7C4.8 8.5 4.25 7.8 4.25 7.05C4.5 7.15 4.75 7.25 5 7.25C4.3 6.75 4.05 5.8 4.5 5.05C5.35 6.05 6.55 6.7 7.9 6.75C7.65 5.75 8.45 4.75 9.5 4.75C9.95 4.75 10.4 4.95 10.7 5.25C11.1 5.15 11.45 5.05 11.75 4.85C11.65 5.25 11.4 5.55 11.05 5.75Z" />
                            </svg>
                        </motion.p>
                        <Tooltip anchorSelect=".twitterButton" place="bottom">
                            Twitter
                        </Tooltip>
                        <motion.p className='instaButton' whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} transition={{ type: "tween", duration: 0.05 }} onClick={handleClickInsta}>
                            <svg className='instaLogo' width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className='svgPath' fill="var(--header-secondary-color)" d="M8 9.4C7.25 9.4 6.6 8.8 6.6 8C6.6 7.25 7.2 6.6 8 6.6C8.75 6.6 9.4 7.2 9.4 8C9.4 8.75 8.75 9.4 8 9.4Z" />
                                <path className='svgPath' fill="var(--header-secondary-color)" fillRule="evenodd" clipRule="evenodd" d="M9.7 4.6H6.3C5.9 4.65 5.7 4.7 5.55 4.75C5.35 4.8 5.2 4.9 5.05 5.05C4.93131 5.16869 4.87523 5.28739 4.80744 5.43085C4.78958 5.46866 4.77085 5.50829 4.75 5.55C4.74227 5.5732 4.73333 5.59761 4.72376 5.62376C4.67145 5.76666 4.6 5.96188 4.6 6.3V9.7C4.65 10.1 4.7 10.3 4.75 10.45C4.8 10.65 4.9 10.8 5.05 10.95C5.16869 11.0687 5.28739 11.1248 5.43085 11.1926C5.46869 11.2104 5.50826 11.2291 5.55 11.25C5.5732 11.2577 5.59761 11.2667 5.62376 11.2762C5.76667 11.3285 5.96188 11.4 6.3 11.4H9.7C10.1 11.35 10.3 11.3 10.45 11.25C10.65 11.2 10.8 11.1 10.95 10.95C11.0687 10.8313 11.1248 10.7126 11.1926 10.5691C11.2104 10.5313 11.2291 10.4917 11.25 10.45C11.2577 10.4268 11.2667 10.4024 11.2762 10.3762C11.3285 10.2333 11.4 10.0381 11.4 9.7V6.3C11.35 5.9 11.3 5.7 11.25 5.55C11.2 5.35 11.1 5.2 10.95 5.05C10.8313 4.93131 10.7126 4.87523 10.5691 4.80744C10.5314 4.78959 10.4917 4.77083 10.45 4.75C10.4268 4.74226 10.4024 4.73333 10.3762 4.72376C10.2333 4.67145 10.0381 4.6 9.7 4.6ZM8 5.85C6.8 5.85 5.85 6.8 5.85 8C5.85 9.2 6.8 10.15 8 10.15C9.2 10.15 10.15 9.2 10.15 8C10.15 6.8 9.2 5.85 8 5.85ZM10.7 5.8C10.7 6.07614 10.4761 6.3 10.2 6.3C9.92386 6.3 9.7 6.07614 9.7 5.8C9.7 5.52386 9.92386 5.3 10.2 5.3C10.4761 5.3 10.7 5.52386 10.7 5.8Z" />
                                <path className='svgPath' fill="var(--header-secondary-color)" fillRule="evenodd" clipRule="evenodd" d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM6.3 3.85H9.7C10.15 3.9 10.45 3.95 10.7 4.05C11 4.2 11.2 4.3 11.45 4.55C11.7 4.8 11.85 5.05 11.95 5.3C12.05 5.55 12.15 5.85 12.15 6.3V9.7C12.1 10.15 12.05 10.45 11.95 10.7C11.8 11 11.7 11.2 11.45 11.45C11.2 11.7 10.95 11.85 10.7 11.95C10.45 12.05 10.15 12.15 9.7 12.15H6.3C5.85 12.1 5.55 12.05 5.3 11.95C5 11.8 4.8 11.7 4.55 11.45C4.3 11.2 4.15 10.95 4.05 10.7C3.95 10.45 3.85 10.15 3.85 9.7V6.3C3.9 5.85 3.95 5.55 4.05 5.3C4.2 5 4.3 4.8 4.55 4.55C4.8 4.3 5.05 4.15 5.3 4.05C5.55 3.95 5.85 3.85 6.3 3.85Z" />
                            </svg>
                        </motion.p>
                        <Tooltip anchorSelect='p.instaButton' place="bottom">
                            Instagram
                        </Tooltip>
                        <motion.p className='discordButton' whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} transition={{ type: "tween", duration: 0.05 }} onClick={handleClickDiscord}>
                            <svg className='discordLogo' width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className='svgPath' fill="var(--header-secondary-color)" fillRule="evenodd" clipRule="evenodd" d="M8.872 9.4C8.992 9.552 9.136 9.724 9.136 9.724C10.02 9.696 10.36 9.116 10.36 9.116C10.36 7.828 9.784 6.784 9.784 6.784C9.208 6.352 8.66 6.364 8.66 6.364L8.604 6.428C9.284 6.636 9.6 6.936 9.6 6.936C9.184 6.708 8.776 6.596 8.396 6.552C8.108 6.52 7.832 6.528 7.588 6.56C7.56705 6.56 7.54914 6.56305 7.52896 6.56648L7.52 6.568C7.38 6.58 7.04 6.632 6.612 6.82C6.464 6.888 6.376 6.936 6.376 6.936C6.376 6.936 6.708 6.62 7.428 6.412L7.388 6.364C7.388 6.364 6.84 6.352 6.264 6.784C6.264 6.784 5.688 7.828 5.688 9.116C5.688 9.116 6.024 9.696 6.908 9.724C6.908 9.724 7.056 9.544 7.176 9.392C6.668 9.24 6.476 8.92 6.476 8.92C6.476 8.92 6.516 8.948 6.588 8.988C6.592 8.992 6.596 8.996 6.604 9C6.61 9.004 6.616 9.007 6.622 9.01C6.628 9.013 6.634 9.016 6.64 9.02C6.74 9.076 6.84 9.12 6.932 9.156C7.096 9.22 7.292 9.284 7.52 9.328C7.82 9.384 8.172 9.404 8.556 9.332C8.744 9.3 8.936 9.244 9.136 9.16C9.276 9.108 9.432 9.032 9.596 8.924C9.596 8.924 9.396 9.252 8.872 9.4ZM6.868 8.3C6.868 8.056 7.048 7.856 7.276 7.856C7.504 7.856 7.688 8.056 7.684 8.3C7.684 8.544 7.504 8.744 7.276 8.744C7.052 8.744 6.868 8.544 6.868 8.3ZM8.328 8.3C8.328 8.056 8.508 7.856 8.736 7.856C8.964 7.856 9.144 8.056 9.144 8.3C9.144 8.544 8.964 8.744 8.736 8.744C8.512 8.744 8.328 8.544 8.328 8.3Z" />
                                <path className='svgPath' fill="var(--header-secondary-color)" fillRule="evenodd" clipRule="evenodd" d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM4.5 5.324C4.5 4.868 4.868 4.5 5.32 4.5H10.68C11.132 4.5 11.5 4.868 11.5 5.324V12.5L10.64 11.74L10.156 11.292L9.644 10.816L9.856 11.556H5.32C4.868 11.556 4.5 11.188 4.5 10.732V5.324Z" />
                            </svg>
                        </motion.p>
                        <Tooltip anchorSelect='p.discordButton' place="bottom">
                            Discord
                        </Tooltip>
                    </div>
                </div>
            </div >
        </header >
    );
};


export default Header