import React from 'react';
import HomeIndex from '../../Components/HomePageComponents/HomeIndex/HomeIndex';
import "./HomePage.css"

const HomePage = ({ onThemeChange }) => {
  return (
    <div>
      <div className="HomeWrapper" />
        <div className="HomeScreensGroup">
          <HomeIndex />
          <div className="MusicPlayer">
          </div>
        </div>
	  </div>
  );
};

export default HomePage;
