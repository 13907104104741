import '../Styles/global.css'
import { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { motion } from 'framer-motion';
import { FaArrowRight } from "react-icons/fa";

export const themes = [
    { index: 0, name: 'kizu', bgColor: 'var(--primary-color)', secColor: 'var(--secondary-color)', icoColor: 'var(--primary-color)', icoSecColor: 'var(--secondary-color)', transpColor: 'var(--transparent-color)' },
    { index: 1, name: 'haze', bgColor: 'transparent', secColor: 'var(--secondary-haze-color)', icoColor: 'var(--primary-haze-color)', icoSecColor: 'var(--secondary-haze-color)', transpColor: 'var(--secondary-haze-color-transparent)' },
    { index: 2, name: 'kai', bgColor: 'transparent', secColor: 'var(--secondary-kai-color)', icoColor: 'var(--primary-kai-color)', icoSecColor: 'var(--secondary-kai-color)', transpColor: 'var(--secondary-kai-color-transparent)' },
    { index: 3, name: 'miyako', bgColor: 'transparent', secColor: 'var(--secondary-miyako-color)', icoColor: 'var(--primary-miyako-color)', icoSecColor: 'var(--secondary-miyako-color)', transpColor: 'var(--secondary-miyako-color-transparent)' },
    { index: 4, name: 'zero', bgColor: 'transparent', secColor: 'var(--secondary-zero-color)', icoColor: 'var(--primary-zero-color)', icoSecColor: 'var(--secondary-zero-color)', transpColor: 'var(--secondary-zero-color-transparent)' },
];

export const changeTheme = (themeChoice) => {
    document.documentElement.style.setProperty('--header-background-color', themeChoice.bgColor || '#9058A0');
    document.documentElement.style.setProperty('--header-secondary-color', themeChoice.secColor || '#b788c4');
    document.documentElement.style.setProperty('--icon-color', themeChoice.icoColor || '#000000');
    document.documentElement.style.setProperty('--icon-color-secondary', themeChoice.icoSecColor || '#b788c4');
    document.documentElement.style.setProperty('--icon-color-transparent', themeChoice.transpColor || 'rgba(183, 136, 196, 0.5)');
};

export const handleFavoriteThemeChange = (theme) => {
    localStorage.setItem("favorite-theme", JSON.stringify(theme));
    console.log("favorite theme changed to: ", theme);
};

export var currentHomeIndex = 0;

export const automatedThemeChange = (location, theme) => {
    const favoriteTheme = JSON.parse(localStorage.getItem("favorite-theme"));
    if (favoriteTheme && location.pathname !== "/") {
        changeTheme(favoriteTheme);
        currentHomeIndex = favoriteTheme.index;
        console.log("we are not currently on the home page and we have a favorite theme selected in local storage: ", favoriteTheme);
        return (favoriteTheme);
    }
    else if (!favoriteTheme && location.pathname !== "/") {
        changeTheme(themes[0]);
        console.log("we are not currently on the home page and we do not have a favorite theme selected in local storage. Default theme selected: ", themes[0]);
        return (themes[0]);
    }
    else {
        if (!currentHomeIndex) { currentHomeIndex = 0; }
        changeTheme(theme);
        console.log("we are currently on the home page. Theme selected: ", theme);
        currentHomeIndex = theme.index;
        console.log("currentHomeIndex: ", currentHomeIndex);
        return (theme);
    }
};

export const changeThemeIndexUpdate = (theme) => {
    changeTheme(theme);
    currentHomeIndex = theme.index;
    console.log("theme changed to: ", theme, "currentHomeIndex: ", currentHomeIndex);
};

export const FavoriteThemeTooltip = (favoriteExists) => {
    if (favoriteExists) {
        const favoriteTheme = JSON.parse(localStorage.getItem("favorite-theme"));
        const favoriteThemeName = favoriteTheme.name;
        sessionStorage.setItem('favoriteThemeLoaded', 'true');
        return (
            <motion.div
                className="favorite-tooltip-container"
                initial={{ opacity: 0 }}
                animate={{
                    opacity: 1,
                    transition: { duration: 1 }
                }}
                exit={{
                    opacity: [null, 0],
                    transition: { duration: 1 }
                }}
            >
                <Tooltip
                    anchorSelect=".theme-tab"
                    place="left"
                    defaultIsOpen="true"
                    isOpen="true"
                >
                    <div className="favorite-tooltip">
                        <div className="favorite-tooltip-text-found">
                            <p>Favorite Theme "</p>
                            <p style={{ textTransform: "capitalize" }}>{favoriteThemeName}</p>
                            <p>" Loaded!</p>
                        </div>
                    </div>
                </Tooltip>
            </motion.div>
        )
    }
    else {
        return (
            <motion.div
                className="favorite-tooltip-container"
                initial={{ opacity: 0 }}
                animate={{
                    opacity: 1,
                    transition: { duration: 1 }
                }}
                exit={{
                    opacity: [null, 0],
                    transition: { duration: 1 }
                }}
            >
                <Tooltip
                    anchorSelect=".theme-tab"
                    place="left"
                    defaultIsOpen="true"
                    isOpen="true"
                >
                    <div className="favorite-tooltip">
                        <div className="favorite-tooltip-text-not-found">
                            <p className='fav-tooltip-line-1'>Favorite theme not set!</p>
                            <p className='fav-tooltip-line-2'>Click to set a favorite theme.</p>
                            <FaArrowRight className='fav-tooltip-arrow' />
                        </div>
                    </div>
                </Tooltip>
            </motion.div>
        )
    }

};


/*     //workaround for safari not updating css variables
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
 
    if (isSafari) {
      // Force a repaint on .header and .mobileHeader
      ['.header', '.mobileHeader'].forEach(selector => {
        const element = document.querySelector(selector);
        if (element) {
          element.style.display='none';
          // eslint-disable-next-line
          element.offsetHeight; // Trigger reflow
          element.style.display='';
        }
      });
    } */
