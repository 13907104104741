import React, { useEffect, useState } from 'react';
import ComingSoon from './Components/Development/ComingSoon';
import MusicPlayer from './Components/MusicPlayer/MusicPlayer';
import Header from './Components/Header/Header';
import MobileHeader from './Components/Header/MobileHeader';
import HomePage from './Pages/HomePage/HomePage';
import Team from './Pages/Team/Team';
import Vision from './Pages/Vision/Vision';
import Exhibits from './Pages/Exhibits/Exhibits';
import Gallery from './Pages/Gallery/Gallery';
import Preloader from './Components/PreLoader/PreLoader';
import Footer from './Components/Footer/Footer';
import { AnimatePresence } from 'framer-motion';
import { useLocation, useRoutes } from "react-router-dom";

function App() {

    const [siteComingSoon, setSiteComingSoon] = useState(true); // Set to true if site is coming soon

    const element = useRoutes([
        { path: '/', element: <HomePage /> },
        { path: '/gallery', element: <Gallery /> },
        { path: '/vision', element: <Vision /> },
        { path: '/exhibits', element: <Exhibits /> },
        { path: '/team', element: <Team /> },
    ]);

    const location = useLocation();

    const visitedBefore = sessionStorage.getItem('visitedBefore');

    return (
        <div>
            <>
                {!visitedBefore && <Preloader />}
                {siteComingSoon && <ComingSoon />}
                {!siteComingSoon && <Header />}
                {!siteComingSoon && <MobileHeader />}
                {!siteComingSoon &&
                    <AnimatePresence mode="wait" initial={false}>
                        {React.cloneElement(element, { key: location.pathname })}
                    </AnimatePresence>
                }
                {!siteComingSoon && <MusicPlayer />}
                {!siteComingSoon && <Footer />}
            </>
        </div>
    );
}

export default App;
