import React from "react";
import "./HomeKai.css";
import { motion } from "framer-motion"
import KaiCharacter from "./img/KaiCharacter.webp"
import { useSwiper } from 'swiper/react';
import { Textfit } from 'react-textfit';
import kizuLogo from '../../../Assets/KizuLogoWHiragana.svg';

const HomeKai = () => {

  const swiper = useSwiper();

  function handlePrev() {
    console.log("handlePrev");
    swiper.slidePrev();
  };

  function handleNext() {
    console.log("handleNext");
    swiper.slideNext();
  };

  return (
    <div className="home-kai-wrapper">
      <div className="home-kai-group">
        <svg className="home-kai-kizuLogoBackground" src={kizuLogo} />
        <div className='home-kai-index-wrapper'>
          <div className={"home-kai-BioBoxGroup"}>
            <div className={"home-kai-BioBoxWithNavButtons"}>
              <Textfit mode="multi" max={38} className={"home-kai-Attributes"}>
                Height: 170 cm{"\n"}Favorite food: Curry
              </Textfit>
              <Textfit mode="multi" max={36} className={"home-kai-BioText"}>
                Kai is a master of the sword. He’s a bit of a lone wolf, but he’s always there when you need him. He’s also a bit of a flirt, so watch out!
              </Textfit>
              <div className={"home-kai-PreviousButtonWrapper"}>
                <motion.div
                  onClick={(event) => handlePrev(event)}
                  className={"home-kai-ButtonWrapper"}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "tween", duration: 0.05 }}
                >
                  <button className={"home-kai-PrevNextButton"}>
                    <Textfit mode="single" forceSingleModeWidth={true} max={48} className={"home-kai-PrevNextButtonText"}>
                      PREV
                    </Textfit>
                  </button>
                </motion.div>
              </div>
              <div className={"home-kai-NextButtonWrapper"}>
                <motion.div
                  onClick={(event) => handleNext(event)}
                  className={"home-kai-ButtonWrapper"}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "tween", duration: 0.05 }}
                >
                  <button className={"home-kai-PrevNextButton"}>
                    <Textfit mode="single" forceSingleModeWidth={true} max={48} className={"home-kai-PrevNextButtonText"}>
                      NEXT
                    </Textfit>
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <Textfit mode="single" max={96} className="home-kai-name" style={{ fontWeight: '900', color: 'white', textShadow: '-2px 2px 2px #363636bf, 2px 2px 2px #363636bf, 2px -2px 2px #363636bf, -2px -2px 2px #363636bf' }}>
          KAI
        </Textfit>
        <div className="home-kai-placard-group">
          <img src={KaiCharacter} className="home-kai-character" alt="Kai Character" />
        </div>
      </div>
    </div>
  );
};

export default HomeKai;
