import React, { useState, useEffect } from 'react';
import './Footer.css'
import { motion, useAnimation } from "framer-motion"
import '../../Styles/global.css'
import { use } from 'chai';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard } from "swiper";
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { getSwiperIndex } from '../../Utils/GlobalFunctions';
import { themes, currentHomeIndex, handleFavoriteThemeChange, changeThemeIndexUpdate } from '../../Utils/ThemeProvider';
import { Tooltip } from 'react-tooltip'

const Footer = (homeIndex) => {

    const tabs = [
        { index: 0, name: "Kizu", color: "var(--primary-color)", backgroundColor: "var(--secondary-color)", backgroundTransparentColor: "var(--transparent-color)" },
        { index: 1, name: "Haze", color: "var(--primary-haze-color)", backgroundColor: "var(--secondary-haze-color)", backgroundTransparentColor: "var(--secondary-haze-color-transparent)" },
        { index: 2, name: "Kai", color: "var(--primary-kai-color)", backgroundColor: "var(--secondary-kai-color)", backgroundTransparentColor: "var(--secondary-kai-color-transparent)" },
        { index: 3, name: "Miyako", color: "var(--primary-miyako-color)", backgroundColor: "var(--secondary-miyako-color)", backgroundTransparentColor: "var(--secondary-miyako-color-transparent)" },
        { index: 4, name: "Zero", color: "var(--primary-zero-color)", backgroundColor: "var(--secondary-zero-color)", backgroundTransparentColor: "var(--secondary-zero-color-transparent)" }
    ];

    const [selected, setSelected] = useState(0);
    const [formerBackgroundColor, setFormerBackgroundColor] = useState(tabs[0].backgroundColor);
    const [isThemeTabOpen, setIsThemeTabOpen] = useState(false);
    const [isTabsVisible, setIsTabsVisible] = useState(false);

    useEffect(() => {
        let timeout;

        if (isThemeTabOpen) {
            timeout = setTimeout(() => {
                setIsTabsVisible(true);
            }, 500); // Delay
        } else {
            clearTimeout(timeout);
            setIsTabsVisible(false);
        }

        return () => clearTimeout(timeout);
    }, [isThemeTabOpen]);

    const location = useLocation();

    useEffect(() => {
        setFavoriteThemeConfirmationVisible(false);
        setIsThemeTabOpen(false);
        setIsTabsVisible(false);
        setFormerBackgroundColor(tabs[0].backgroundColor)
    }, [location.pathname])


    const [favoriteThemeConfirmationVisible, setFavoriteThemeConfirmationVisible] = useState(false);

    const themeSaveConfirmation = () => {
        let currentThemeName = tabs[currentHomeIndex].name || "Kizu";
        return (
            <p className="confirmation-dialog-text" key={homeIndex}>
                This is the {currentThemeName} theme. Would you like to set this as your favorite theme?
            </p>
        )
    };


    const handleThemeButtonPress = (themeName) => {
        if (location.pathname === "/" && favoriteThemeConfirmationVisible === false) {
            setFavoriteThemeConfirmationVisible(true);
            setSelected(currentHomeIndex);
        }
        else if (location.pathname === "/" && favoriteThemeConfirmationVisible === true) {
            setFavoriteThemeConfirmationVisible(false);
        }
        else if (location.pathname !== "/") {
            setIsThemeTabOpen(!isThemeTabOpen);
        }
    };

    const [favoriteThemeName, setFavoriteThemeName] = useState(null);

    /*     useEffect(() => {
            console.log(favoriteThemeName);
        }, [favoriteThemeName]) */


    const handleCloseDialog = () => {
        setFavoriteThemeConfirmationVisible(false);
    };

    const handleOK = (selectedIndex) => {
        setFavoriteThemeConfirmationVisible(false);
        const name = tabs[selectedIndex].name;
        setFavoriteThemeName(name);
        const theme = themes[selectedIndex];
        handleFavoriteThemeChange(theme);
    };

    const DialogModal = (isVisible) => {

        const Path = props => (
            <motion.path
                fill="transparent"
                strokeWidth="3"
                stroke="hsl(0, 0%, 18%)"
                strokeLinecap="round"
                {...props}
            />
        );

        const OKButton = ({ close }) => (
            <motion.div
                onClick={close}
                className="ok"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
            >
                YES!
            </motion.div>
        );

        const CloseButton = ({ close }) => (
            <button onClick={close} className="close">
                <svg width="23" height="23" viewBox="0 0 23 23">
                    <Path d="M 3 3 L 20 20" stroke="var(--icon-color-secondary)" />
                    <Path d="M 3 20 L 20 3" stroke="var(--icon-color-secondary)" />
                </svg>
            </button>
        );

        return (
            <AnimatePresence initial={false}>
                {favoriteThemeConfirmationVisible && <motion.div
                    name="Confirmation Dialog Background"
                    className="confirmation-dialog-background"
                    initial={{ opacity: 0, x: 100, y: 75, scale: 0.25 }}
                    animate={{ opacity: 1, x: 0, y: 0, scale: 1, transition: { duration: 0.2 } }}
                    exit={{ opacity: 0, x: 100, y: 75, scale: 0.25, transition: { duration: 0.2 } }}
                >
                    {themeSaveConfirmation()}
                    <OKButton
                        close={() => handleOK(currentHomeIndex)}
                    />
                    <CloseButton
                        close={() => handleCloseDialog()}
                    />
                </motion.div>}
            </AnimatePresence>
        )
    };

    //TODO: FIX THE THEME TAB FAVORITE CONFIRMATION NOT UPDATING TO THE CURRENT INDEX WHEN YOU NAVIGATE BETWEEN HOME PAGES

    return (
        <div className="footer">
            <div className="footer-content">
                {DialogModal(favoriteThemeConfirmationVisible)}
                <motion.div
                    name="Theme Tab Container"
                    className="theme-tab-container"
                    initial={{
                        width: isThemeTabOpen === true ? "400px" : "auto",
                        backgroundColor: isThemeTabOpen === true ? "rgba(55, 55, 55, 0.8)" : "rgba(255, 255, 255, 0.1)"
                    }}
                    animate={{
                        width: isThemeTabOpen === true ? "400px" : "auto",
                        backgroundColor: isThemeTabOpen === true ? "rgba(55, 55, 55, 0.8)" : "rgba(255, 255, 255, 0.1)"
                    }}
                    transition={{ duration: 0.5 }}
                >
                    <motion.div
                        name="Theme Tab"
                        className="theme-tab"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.9 }}
                        initial={{
                            borderTopRightRadius: isThemeTabOpen === true ? "0px" : "15px",
                            borderBottomRightRadius: isThemeTabOpen === true ? "0px" : "15px",
                            marginRight: isThemeTabOpen === true ? "5px" : "0px"
                        }}
                        animate={{
                            borderTopRightRadius: isThemeTabOpen === true ? "0px" : "15px",
                            borderBottomRightRadius: isThemeTabOpen === true ? "0px" : "15px",
                            marginRight: isThemeTabOpen === true ? "5px" : "0px"
                        }}
                        onTap={() => handleThemeButtonPress(tabs[currentHomeIndex].name)}
                    >
                        THEME
                    </motion.div>
                    {!isThemeTabOpen && !isTabsVisible && location.pathname !== "/" && <Tooltip anchorSelect=".theme-tab" place="top">
                        Select a different<br /> theme or choose <br />a favorite theme.
                    </Tooltip>}
                    {isTabsVisible && isThemeTabOpen && tabs.map(({ name, backgroundColor, }, i) => (
                        <motion.ul
                            name="Tab"
                            className="tab"
                            key={i}
                            whileHover={{
                                backgroundColor: tabs[i].backgroundTransparentColor,
                            }}
                            initial={{
                                color: i === selected ? tabs[i].color : tabs[i].backgroundColor,
                            }}
                            animate={{
                                color: i === selected ? tabs[i].color : tabs[i].backgroundColor,
                            }}
                            onTap={() => {
                                setFormerBackgroundColor(tabs[selected].backgroundColor);
                                setSelected(i);
                                changeThemeIndexUpdate(themes[i]);
                                setFavoriteThemeConfirmationVisible(true);
                            }}
                            transition={{ duration: .25 }}
                        >
                            {name}
                            {i === selected && (
                                <motion.div
                                    name="Background"
                                    className="selection"
                                    layoutId="selected"
                                    style={{ borderRadius: 30 }}
                                    initial={{ backgroundColor: formerBackgroundColor }}
                                    animate={{ backgroundColor: backgroundColor }}
                                    transition={{ duration: .25 }}
                                />
                            )}
                        </motion.ul>
                    ))}
                </motion.div>
            </div >
        </div >
    )
}

export default Footer;
