import { motion } from "framer-motion";

const TransitionSwipe = (isPresent) => {
    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: .5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: .5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="transition-swipe"
            />
        </div>
    )
}

export default TransitionSwipe;