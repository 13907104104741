import React from 'react';
import CollectionCard from './CollectionCard';
import './CollectionCard.css';

const NFTList = ({ nftListData }) => {
  // Check if nftListData is defined and not empty
  if (!nftListData || nftListData.length === 0) {
    return (
      <div className="nftList">
        <p>No NFTs available</p>
      </div>
    );
  }

  return (
    <div className="nftList">
      {nftListData.map(nft => (
        <div key={nft.identifier} className="collection-card">
          <CollectionCard
            id={nft.identifier}
            name={nft.name}
            image={nft.image_url}
          />
        </div>
      ))}
    </div>
  );
};

export default NFTList;
